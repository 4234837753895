<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Detail "{{ data.code }}"
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container :data="data" />
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Container from './Container'
import BreadCrumb from '@/components/globals/breadcrumb'

export default {
  components: {
    Container,
    BreadCrumb
  },
  data() {
    return {
      breadcrumb: [
        {
          link: '/admin/permintaan-panen',
          title: 'Daftar Permintaan Panen'
        },
        {
          link: null,
          title: 'Detail'
        }
      ]
    }
  },
  computed: {
    ...mapGetters({
      data: 'harvestRequest/harvestRequestDetail'
    })
  },
  async mounted() {
    await this.getHarvestRequestDetail(this.$route.params.id)
  },
  methods: {
    ...mapActions({
      getHarvestRequestDetail: 'harvestRequest/getHarvestRequestDetail'
    })
  }
}
</script>
