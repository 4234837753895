<template>
  <div class="uk-margin-top">
    <div class="uk-card uk-card-default kandang-card">
      <div
        class="uk-child-width-expand@s uk-grid-small"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-1-1@m uk-width-1-1@l uk-text-right">
          <button
            v-if="!is_loading"
            class="uk-button uk-button-primary uk-margin-left"
            :disabled="is_loading"
            @click="handleSync"
          >
            <img
              v-lazy="`${images}/icon/sync.svg`"
              alt=""
              class="uk-margin-small-right img-click"
            >
            Sync Data
          </button>
          <button
            v-else
            style="cursor:unset; min-width:167.47px;"
            class="uk-button uk-button-primary uk-margin-left"
          >
            <div uk-spinner />
          </button>
        </div>
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Sales Shipment
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataDetail.code"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kode Siklus
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataDetail.log_cycle_code"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Mitra
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataDetail.farm_name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Kandang
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataDetail.cage_name"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Siklus ke
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <input
                v-model="dataDetail.cycle_count"
                class="uk-input"
                type="text"
                disabled
              >
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Kota/Kabupaten
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.city"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Tanggal Panen
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.harvest_date"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Usia Panen (hari)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.harvest_age"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
        </div>
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nama Pembeli
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.customer_name"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Jumlah (ekor)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.number_of_tail"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Total Bobot (kg)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.total_weight"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Berat Rataan (kg)
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.average_weight"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Nopol Mobil
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.no_police"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Update At
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-form-controls">
                <input
                  v-model="dataDetail.updated_at"
                  class="uk-input"
                  type="text"
                  disabled
                >
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Status
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div :class="data.status == 'completed' || data.status == 'confirmed' ? 'status-success' : data.status == 'rejected' ? 'status-danger' : 'status-warning'">
                {{ setCapitalize(dataDetail.status) }}
              </div>
            </div>
          </div>
          <div
            class="uk-child-width-expand@s uk-grid-small"
            uk-grid
          >
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl">
              Pengisian Data Panen
            </div>
            <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div :class="dataDetail.is_done == 1 ? 'status-success' : 'status-warning'">
                  <span v-if="dataDetail.is_done == 1">Sudah</span>
                  <span v-else>Belum</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        class="uk-child-width-expand@s uk-margin-top"
        uk-grid
      >
        <div class="uk-width-1-1@s uk-width-2-3@m uk-width-1-2@l">
          <router-link
            class="uk-button uk-button-default uk-width-expand uk-width-auto@s"
            to="/admin/permintaan-panen"
          >
            <img
              v-lazy="`${images}/icon/back.svg`"
              alt=""
              class="uk-margin-small-right"
            >
            Kembali
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from 'vuex'
import { PREFIX_IMAGE } from "@/utils/constant"
import { dateUtcParanjeString } from "@/utils/formater"
import {
  notificationSuccess
} from '@/utils/notification'

export default {
  props: {
    data: {
      required: true,
      type: Object
    }
  },
  data() {
    return {
      images: PREFIX_IMAGE,
      dataDetail: {},
      is_loading: false
    }
  },
  computed:{
    ...mapGetters({
      syncDataHarvest: 'harvestRequest/sync_data'
    })
  },
  watch: {
    data() {
      this.dataDetail = {
        code: this.data.code || "-",
        log_cycle_code: this.data.log_cycle_code || "-",
        farm_name: this.data.farm_name || "-",
        cage_name: this.data.cage_name || "-",
        cycle_count: this.data.cycle_count || "-",
        city: this.data.city || "-",
        harvest_date: dateUtcParanjeString(this.data.harvest_date) || "-",
        harvest_age: this.data.harvest_age || "-",
        customer_name: this.data.customer_name || "-",
        number_of_tail: this.data.number_of_tail || "-",
        total_weight: this.data.total_weight || "-",
        average_weight: this.data.average_weight || "-",
        no_police: this.data.no_police || "-",
        status: this.data.status || "-",
        is_done: this.data.is_done || "-",
        updated_at: dateUtcParanjeString(this.data.updated_at) || "-"
      }
    }
  }, methods: {
    setCapitalize(q) {
      return q[0].toUpperCase() + q.slice(1)
    },
    ...mapActions({
      syncHarvestRequest: 'harvestRequest/syncHarvestRequest'
    }),
    async handleSync () {
      this.is_loading = true
      await this.syncHarvestRequest({ codes: String(this.dataDetail.code), sync_type: 'all' })
      if (this.syncDataHarvest.message == 'OK') {
        console.log('berhasil')
        notificationSuccess(`Sync ${this.dataDetail.code} Berhasil`)
        setTimeout(function() { window.location.reload()}, 1000)
      }
      console.log(this.syncDataHarvest.message +' gagal')
      this.is_loading = false
    }
  }
}
</script>

